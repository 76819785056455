// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-templates-award-index-tsx": () => import("./../../../src/templates/Award/index.tsx" /* webpackChunkName: "component---src-templates-award-index-tsx" */),
  "component---src-templates-career-pathway-index-tsx": () => import("./../../../src/templates/CareerPathway/index.tsx" /* webpackChunkName: "component---src-templates-career-pathway-index-tsx" */),
  "component---src-templates-community-index-tsx": () => import("./../../../src/templates/Community/index.tsx" /* webpackChunkName: "component---src-templates-community-index-tsx" */),
  "component---src-templates-page-index-tsx": () => import("./../../../src/templates/Page/index.tsx" /* webpackChunkName: "component---src-templates-page-index-tsx" */),
  "component---src-templates-post-index-tsx": () => import("./../../../src/templates/Post/index.tsx" /* webpackChunkName: "component---src-templates-post-index-tsx" */),
  "component---src-templates-program-index-tsx": () => import("./../../../src/templates/Program/index.tsx" /* webpackChunkName: "component---src-templates-program-index-tsx" */),
  "component---src-templates-school-index-tsx": () => import("./../../../src/templates/School/index.tsx" /* webpackChunkName: "component---src-templates-school-index-tsx" */),
  "component---src-templates-specialty-index-tsx": () => import("./../../../src/templates/Specialty/index.tsx" /* webpackChunkName: "component---src-templates-specialty-index-tsx" */)
}

